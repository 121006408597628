import { Config } from './index';
import { LOCALE } from '../i18n';

const featureEnv = process.env.REACT_APP_FEATURE_ENV;

let config: Config = {
  graph: 'https://graph.dev-crazygames.be',
  ga4ID: 'G-QZHWTZQ3TH',
  firebase: {
    apiKey: 'AIzaSyDESdV81A6vor7xlKHWzJeR0qSUp5JjbOI',
    authDomain: 'crazygames-dev.firebaseapp.com',
    databaseURL: 'https://crazygames-dev.firebaseio.com',
    projectId: 'crazygames-dev',
    storageBucket: 'crazygames-dev.appspot.com',
    messagingSenderId: '103229312039',
  },
  sentry: {
    dsn: 'https://b1c4f739f0784719a50fc8252af97e43@sentry.io/1284881',
  },
  aws: {
    filesDomain: 'files.dev-crazygames.be',
    videoCdnPrefix: 'https://videos.dev-crazygames.be',
    imagesCdnPrefix: 'https://imgs.dev-crazygames.be',
  },
  domains: {
    en_US: {
      base: 'https://portal.dev-crazygames.be',
      pathname: '',
    },
    nl_NL: {
      base: 'https://nl-portal.dev-crazygames.be',
      pathname: '',
    },
    it_IT: {
      base: 'https://it-portal.dev-crazygames.be',
      pathname: '',
    },
    es_ES: {
      base: 'https://portal.dev-crazygames.be',
      pathname: '/es',
    },
    id_ID: {
      base: 'https://id-portal.dev-crazygames.be',
      pathname: '',
    },
    fr_FR: {
      base: 'https://fr-portal.dev-crazygames.be',
      pathname: '',
    },
    pt_BR: {
      base: 'https://br-portal.dev-crazygames.be',
      pathname: '',
    },
    ru_RU: {
      base: 'https://ru-portal.dev-crazygames.be',
      pathname: '',
    },
    de_DE: {
      base: 'https://de-portal.dev-crazygames.be',
      pathname: '',
    },
    pl_PL: {
      base: 'https://pl-portal.dev-crazygames.be',
      pathname: '',
    },
    ro_RO: {
      base: 'https://ro-portal.dev-crazygames.be',
      pathname: '',
    },
    nb_NO: {
      base: 'https://no-portal.dev-crazygames.be',
      pathname: '',
    },
    sv_SE: {
      base: 'https://se-portal.dev-crazygames.be',
      pathname: '',
    },
    fi_FI: {
      base: 'https://fi-portal.dev-crazygames.be',
      pathname: '',
    },
    uk_UA: {
      base: 'https://uk-portal.dev-crazygames.be',
      pathname: '',
    },
    el_GR: {
      base: 'https://gr-portal.dev-crazygames.be',
      pathname: '',
    },
    da_DK: {
      base: 'https://portal.dev-crazygames.be',
      pathname: '/dk',
    },
    cs_CZ: {
      base: 'https://cz-portal.dev-crazygames.be',
      pathname: '',
    },
    hu_HU: {
      base: 'https://hu-portal.dev-crazygames.be',
      pathname: '',
    },
    tr_TR: {
      base: 'https://portal.dev-crazygames.be',
      pathname: '/tr',
    },
    ar_SA: {
      base: 'https://ar-portal.dev-crazygames.be',
      pathname: '',
    },
    vi_VN: {
      base: 'https://vn-portal.dev-crazygames.be',
      pathname: '',
    },
    th_TH: {
      base: 'https://th-portal.dev-crazygames.be',
      pathname: '',
    },
    ko_KR: {
      base: 'https://kr-portal.dev-crazygames.be',
      pathname: '',
    },
  },
  kidSiteDomain: {
    base: 'https://kids.dev-crazygames.be',
  },
};

if (featureEnv) {
  config.graph = `https://graph-${featureEnv}.dev-crazygames.be`;

  // Append `-featureEnv` to the base of each domain
  Object.keys(config.domains).forEach((key) => {
    const domain = config.domains[key as LOCALE];
    const domainsSplit = domain.base.split('.');
    domainsSplit[0] = `${domainsSplit[0]}-${featureEnv}`;
    domain.base = domainsSplit.join('.');
  });
}

export default config;
